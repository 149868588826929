import React, { useRef, useEffect } from 'react';
import { useApiState } from '../hooks/useApiState';
import { getColorProfile } from '../utils/colorPalatte';

interface Message {
  role: 'user' | 'assistant';
  content: string;
  image?: string;
}

interface ChatUIProps {
  messages: Message[];
  accumulatedTranscript: string;
  isTranscriptFinal: boolean;
  colorProfile: string;
}

const ChatUI: React.FC<ChatUIProps> = ({ messages, accumulatedTranscript, isTranscriptFinal, colorProfile }) => {
  const { apiState } = useApiState();
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const colors = getColorProfile(colorProfile);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages, accumulatedTranscript]);

  return (
    <div
      ref={chatContainerRef}
      style={{
        height: '100%',
        overflowY: 'auto',
        border: '0px',
        padding: '0px',
        display: 'flex',
        backgroundColor: colors.backgroundColor,
        color: colors.color,
        fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif', // Apply system font stack
      }}
    >
      <div 
        style={{ 
          display: 'flex', 
          flexDirection: 'column', 
          marginTop: 'auto', 
          padding: '1rem 0',
          width: '100%',
        }}>
        {messages.map((message, index) => (
          message.image ? (
            <div 
              key={index}
              style={{
                alignSelf: 'flex-end',
                backgroundColor: colors.userMessageBackgroundColor,
                color: colors.userMessageColor,
                borderRadius: '10px',
                padding: '0',
                margin: '8px 0',
                maxWidth: '50%',
                aspectRatio: '1/1',
                objectFit: 'cover',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                fontSize: '14px',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <img 
                src={message.image} 
                alt="Message attachment" 
                style={{
                  maxWidth: '100%',
                  height: 'auto',
                  aspectRatio: '1/1',
                  borderRadius: '8px',
                  objectFit: 'cover',
                }}
              />
            </div>
          ) : (
            <div
              key={index}
              style={{
                alignSelf: message.role === 'user' ? 'flex-end' : 'flex-start',
                backgroundColor: message.role === 'user' ? colors.userMessageBackgroundColor : colors.tutorMessageBackgroundColor,
                color: message.role === 'user' ? colors.userMessageColor : colors.tutorMessageColor,
                borderRadius: '10px',
                padding: '8px 12px',
                margin: '8px 0',
                maxWidth: '70%',
                fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
                fontSize: '14px',
              }}
            >
              {message.content.split('\n').map((line, lineIndex) => (
                <React.Fragment key={lineIndex}>
                  {line}
                  {lineIndex < message.content.split('\n').length - 1 && <br />}
                </React.Fragment>
              ))}
            </div>
        )))}
        {accumulatedTranscript && (
          <div
            style={{
              alignSelf: 'flex-end',
              backgroundColor: isTranscriptFinal ? colors.userMessageBackgroundColor : colors.userMessageInProgressColor,
              color: colors.userMessageColor,
              borderRadius: '10px',
              padding: '8px 12px',
              margin: '8px 0',
              maxWidth: '70%',
              opacity: 1,
              transition: 'background-color 0.3s ease',
              fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
              fontSize: '14px',
            }}
          >
            {accumulatedTranscript}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatUI;