import { useEffect, useRef, useCallback, useState, useMemo } from 'react';
import { debounce } from 'lodash';
import { AvatarClient } from 'lib/avatar/AvatarClient';
import { AzureSpeechRecognition } from 'lib/speech/AzureSpeechRecognition';
import ChatUI from './components/ChatUI';
import { useApiState } from './hooks/useApiState';
import { getColorProfile } from './utils/colorPalatte';

const TUTOR_API_BACKEND_URL = process.env.REACT_APP_TUTOR_API_BACKEND_URL;
const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;

if (!TUTOR_API_BACKEND_URL) {
  throw new Error('REACT_APP_TUTOR_API_BACKEND_URL environment variable is not defined');
}

if (!WEBSOCKET_URL) {
  throw new Error('REACT_APP_WEBSOCKET_URL environment variable is not defined');
}

export function App() {
  const { apiState, fetchInitialState, updateApiState } = useApiState();
  const avatarIdRef = useRef<number | undefined>(undefined);
  const avatar = useMemo(() => new AvatarClient({
    apiKey: process.env.REACT_APP_AVATAR_API_KEY || '',
    baseUrl: process.env.REACT_APP_AVATAR_BASE_URL || '',
    avatarId: avatarIdRef.current
  }), []);

  const speechRecognition = useMemo(() => new AzureSpeechRecognition(), []);

  const [conversationHistory, setConversationHistory] = useState<{ role: 'user' | 'assistant'; content: string }[]>([]);
  const [accumulatedTranscript, setAccumulatedTranscript] = useState('');
  const [conversationStarted, setConversationStarted] = useState(false);
  const [isTranscriptFinal, setIsTranscriptFinal] = useState(true);
  const videoRef = useRef<HTMLVideoElement>(null);
  const audioRef = useRef<HTMLAudioElement>(null);
  const isReadyRef = useRef(false);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const wsRef = useRef<WebSocket | null>(null);
  const isAvatarInitializedRef = useRef(false);
  const speakingRateRef = useRef<number>(1.0);
  const layoutRef = useRef<'horizontal' | 'vertical'>('vertical');
  const [lastSpokenText, setLastSpokenText] = useState('');
  const [lastSpokenSentence, setLastSpokenSentence] = useState('');
  const customVideoWidthRef = useRef<string>('');
  const customVideoHeightRef = useRef<string>('');
  const customMessageHistoryWidthRef = useRef<string>('');
  const customMessageHistoryHeightRef = useRef<string>('');
  const customVideoObjectFitRef = useRef<"contain" | "cover" | "">('');

  const initializeAvatar = useCallback(async () => {
    isAvatarInitializedRef.current = false;
    if (videoRef.current && audioRef.current && avatarIdRef.current !== null) {
      try {
        console.log('Initializing avatar...');
        await avatar.init(videoRef.current, audioRef.current);
        console.log('Avatar initialized');
        console.log('Initializing connection with avatarId:', avatarIdRef.current);
        await avatar.connect(avatarIdRef.current);
        console.log('Avatar connected');
        isAvatarInitializedRef.current = true;
      } catch (error) {
        console.error("Error initializing avatar:", error);
      }
    }
    console.log('initializeAvatar function executed');
  }, [avatar, videoRef, audioRef]);

  const getPitch = (avatarId: number | undefined): string => {
    if (avatarId === undefined) {
      return 'medium';
    } else if (avatarId === 43) { // Maya Angelou
      return '-17%';
    } else if (avatarId === 51) { // Abraham Lincoln
      return '-5%';
    } else if (avatarId === 57) { // George Washington
      return '-10%';
    } else if (avatarId === 39) { // Marie Cure
      return '-5%';
    } else if (avatarId === 49) { // Henry Ford
      return '-10%';
    } else if (avatarId === 56) { // Mark Twain
      return '+12%';
    } else if (avatarId === 55) { // Alexander the Great
      return '-5%';
    } else if (avatarId === 65) { // Christopher Columbus
      return '-8%';
    } else if (avatarId === 41) { // Isaac Newton
      return '-5%';
    } else if (avatarId === 30) { // William Shakespeare
      return '-3%';
    } else if (avatarId === 91) { // Thomas Edison
      return '-5%';
    } else if (avatarId === 93) { // Amelia Earhart
      return '-3%';
    } else {
      return 'medium';
    }
  };

  const getVoiceOverride = (avatarId: number | undefined): string | undefined => {
    if (avatarId === 43) { // Maya Angelou
      return 'en-US-EmmaNeural';
    } else if (avatarId === 51) { // Abraham Lincoln
      return 'en-US-ChristopherNeural';
    } else if (avatarId === 57) { // George Washington
      return 'en-US-AndrewNeural';
    } else if (avatarId === 39) { // Marie Cure
      return 'en-GB-AbbiNeural';
    } else if (avatarId === 56) { // Mark Twain
      return 'en-US-DavisNeural';
    } else if (avatarId === 55) { // Alexander the Great
      return 'en-GB-ElliotNeural';
    } else if (avatarId === 65) { // Christopher Columbus
      return 'en-GB-NoahNeural';
    } else if (avatarId === 42) { // Jane Austen
      return 'en-GB-BellaNeural';
    } else if (avatarId === 71) { // Napoleon Bonaparte
      return 'en-GB-ThomasNeural';
    } else if (avatarId === 30) { // William Shakespeare
      return 'en-GB-AlfieNeural';
    } else if (avatarId === 91) { // Thomas Edison
      return 'en-US-EricNeural';
    } else if (avatarId === 93) { // Amelia Earhart
      return 'en-US-JaneNeural';
    } else {
      return undefined;
    }
  };

  const getVoiceStyle = (avatarId: number | undefined): string | undefined => {
    if (avatarId === 93) { // Amelia Earhart
      return 'friendly';
    } else {
      return undefined;
    }
  };

  const getVoiceSettings = () => {
    const settings: any = {
      prosody: {
        rate: `${speakingRateRef.current}`,
      },
    };

    const pitch = getPitch(avatarIdRef.current);
    if (pitch) {
      settings.prosody.pitch = pitch;
    }

    const voiceOverride = getVoiceOverride(avatarIdRef.current);
    if (voiceOverride) {
      settings.voiceName = voiceOverride;
    }

    const voiceStyle = getVoiceStyle(avatarIdRef.current);
    if (voiceStyle) {
      settings.style = voiceStyle;
    }

    // Remove undefined properties
    Object.keys(settings).forEach(key => 
      settings[key] === undefined ? delete settings[key] : {});
    Object.keys(settings.prosody).forEach(key => 
      settings.prosody[key] === undefined ? delete settings.prosody[key] : {});

    return settings;
  };

  const speakResponse = useCallback(async (text: string) => {
    if (!apiState?.isVideoEnabled && !apiState?.isAudioEnabled) {
      console.log('Avatar video and voice are disabled, skipping speakResponse');
      return;
    }
    setLastSpokenText(text);
    console.log('Speaking response:', text);
    const sentences = text.split(/(?<=[.!?])\s+/).map(sentence => sentence.trim()).filter(sentence => sentence);

    for (const sentence of sentences) {
      try {
        const voiceSettings = getVoiceSettings();
        await avatar.say(sentence, voiceSettings);
        // The AvatarClient doesn't provide a callback for when a sentence is spoken,
        // so for now don't set the last spoken sentence. This will cause the full text
        // to be spoken again.
        // setLastSpokenSentence(sentence);
      } catch (error) {
        console.error('Error in avatar.say:', error);
        await avatar.say(sentence);
        // The AvatarClient doesn't provide a callback for when a sentence is spoken,
        // so for now don't set the last spoken sentence. This will cause the full text
        // to be spoken again.
        // setLastSpokenSentence(sentence);
      }
    }
  }, [avatar, avatarIdRef, apiState?.isVideoEnabled, apiState?.isAudioEnabled]);

  const resumeLastMessageFromLastSpokenSentence = useCallback(async () => {
    if (avatar.isSpeaking) {
      console.log('Avatar is speaking on resume, stopping...');
      avatar.stop();
    }
    console.log('Resuming last message from last spoken sentence');
    console.log('Last spoken text:', lastSpokenText);
    console.log('Last spoken sentence:', lastSpokenSentence);
    if (!lastSpokenText) {
      console.warn('No last spoken text to resume');
      return;
    }

    if (!lastSpokenSentence) {
      console.log('No last spoken sentence, speaking entire text');
      await speakResponse(lastSpokenText);
      return;
    }

    if (lastSpokenText.includes(lastSpokenSentence)) {
      const remainingText = lastSpokenText.substring(lastSpokenText.indexOf(lastSpokenSentence));
      console.log('Resuming from last spoken sentence:', lastSpokenSentence);
      await speakResponse(remainingText);
    } else {
      console.log('Last spoken sentence not found in last spoken text, speaking entire text');
      await speakResponse(lastSpokenText);
    }
  }, [speakResponse, avatar, lastSpokenText, lastSpokenSentence]);

  const debouncedHandleUserInput = useMemo(() => 
    debounce(async (userInput: string) => {
      console.log('User input:', userInput, 'SessionId:', sessionId);
      if (!sessionId) {
        console.warn('No sessionId found, skipping conversation request');
        return;
      }
  
      if (!conversationStarted) {
        setConversationStarted(true);
      }
  
      setConversationHistory(prev => [...prev, { role: 'user', content: userInput }]);
      setAccumulatedTranscript('');
  
      try {
        const response = await fetch(`${TUTOR_API_BACKEND_URL}/conversation/${sessionId}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            message: userInput
          }),
        });
  
        if (response.ok) {
          const data = await response.json();
          const assistantResponse = data.response.content;
          setConversationHistory(prev => [...prev, { role: 'assistant', content: assistantResponse }]);
          await speakResponse(assistantResponse);
        } else {
          const errorText = await response.text();
          throw new Error(`Server responded with ${response.status}: ${errorText}`);
        }
      } catch (error) {
        console.error("Error getting LLM response:", error);
      }
    }, 300), [sessionId, conversationStarted, speakResponse]);
  
  const handleUserInput = useCallback(
    (userInput: string) => debouncedHandleUserInput(userInput),
    [debouncedHandleUserInput]
  );  

  const onSpeechRecognized = useCallback((text: string, isFinal: boolean) => {
    if (text.trim() && text.trim().toLowerCase() !== "play." && text.trim().toLowerCase() !== "i.") {
      if (avatar.isSpeaking) {
        avatar.stop();
      }

      setIsTranscriptFinal(isFinal);

      if (isFinal) {
        setAccumulatedTranscript(text.trim());
        handleUserInput(text.trim());
      } else {
        setAccumulatedTranscript(text.trim());
      }
    }
  }, [handleUserInput, avatar]);

  const processAvatarSpeakIntro = useCallback((message: string) => {
    console.log('Processing avatar speak intro');
    const checkAvatarReady = () => {
      return isAvatarInitializedRef.current && avatar.isConnected && isReadyRef.current;
    };

    const speakIntro = async () => {
      console.log('Avatar ready, waiting 500ms before speaking intro');
      await new Promise(resolve => setTimeout(resolve, 500));
      console.log('Speaking intro');
      await speakResponse(message);
      setTimeout(() => {
        setConversationHistory(prev => [...prev, { role: 'assistant', content: message }]);
      }, 1000);
    };

    if (checkAvatarReady()) {
      speakIntro();
    } else {
      console.log('Avatar not ready, waiting...');
      const interval = setInterval(() => {
        if (checkAvatarReady()) {
          clearInterval(interval);
          speakIntro();
        } else {
          console.log('Avatar not ready, continuing to wait...');
        }
      }, 100);
    }
  }, [avatar, speakResponse, setConversationHistory]);  

  const handleMessageRef = useRef<((event: MessageEvent) => void) | null>(null);

  const initSession = useCallback(async () => {
    if (!wsRef.current) {
      const ws = new WebSocket(WEBSOCKET_URL!);
      wsRef.current = ws;
  
      ws.onopen = () => {
        console.log('WebSocket connection established');
      };
      ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
      ws.onclose = (event) => {
        console.log('WebSocket connection closed', event);
      };
      ws.onmessage = (event: MessageEvent) => {
        if (handleMessageRef.current) {
          const message = JSON.parse(event.data);
          console.log('WebSocket message:', message);
          handleMessageRef.current(event);
        }
      };
  
      isReadyRef.current = true;
    }
  }, []);

  const startSpeechRecognition = useCallback(async () => {
    console.log('Starting speech recognition');
    if (speechRecognition.isRunning()) {
      console.log('Speech recognition is already running');
      return;
    }
    try {
      await speechRecognition.start(
        process.env.REACT_APP_AZURE_SPEECH_KEY || '',
        process.env.REACT_APP_AZURE_SPEECH_REGION || '',
        onSpeechRecognized,
        () => null
      );
      setAccumulatedTranscript('');
    } catch (error) {
      console.error("Error starting speech recognition:", error);
    }
  }, [speechRecognition, onSpeechRecognized]);

  const stopSpeechRecognition = useCallback(async () => {
    console.log('Stopping speech recognition');
    if (!speechRecognition.isRunning()) {
      console.log('Speech recognition is already stopped');
      return;
    }
    try {
      await speechRecognition.stop();
      setAccumulatedTranscript('');
    } catch (error) {
      console.error("Error stopping speech recognition:", error);
    }
  }, [speechRecognition]);

  const toggleSpeechRecognition = useCallback(async () => {
    console.log('Toggling speech recognition');
    if (speechRecognition.isRunning()) {
      await stopSpeechRecognition();
    } else {
      await startSpeechRecognition();
    }
  }, [speechRecognition, startSpeechRecognition, stopSpeechRecognition]);

  const endSession = useCallback(async () => {
    await avatar.disconnect();
    await stopSpeechRecognition();
    setSessionId(null);
    setConversationStarted(false);
    setConversationHistory([]);
    setAccumulatedTranscript('');
    setIsTranscriptFinal(true);
    isReadyRef.current = false;
    isAvatarInitializedRef.current = false;
    // Clean up WebSocket connection
    if (wsRef.current) {
      wsRef.current.close();
      wsRef.current = null;
    }
  }, [avatar, wsRef, stopSpeechRecognition]);

  const switchAvatar = useCallback(async (newAvatarId: number) => {
    console.log('Switching avatar...');
    isAvatarInitializedRef.current = false;
    await avatar.disconnect();
    try {
      if (videoRef.current && audioRef.current) {
        await avatar.init(videoRef.current, audioRef.current);
        await avatar.connect(newAvatarId);
        console.log('Avatar switched and connected');
        avatarIdRef.current = newAvatarId;
        isAvatarInitializedRef.current = true;
      } else {
        console.error('Video or audio element is null');
      }
    } catch (error) {
      console.error("Error switching avatar:", error);
    }
  }, [avatar, videoRef, audioRef]);

  const handleMessage = useCallback(async (event: MessageEvent) => {
    const messageData = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;
    const { type, sessionId: messageSessionId, avatarId, speakingRate, layout } = messageData;
    console.log('Received message:', event.data, 'SessionId:', sessionId, 'MessageSessionId:', messageSessionId);
    if (messageSessionId !== sessionId) return;

    if (type === 'INIT_AUDIO') {
      console.log('INIT_AUDIO message received');
      await initSession();
    } else if (type === 'END_SESSION') {
      console.log('END_SESSION message received');
      await endSession();
    } else if (type === 'START_SPEECH_RECOGNITION') {
      console.log('START_SPEECH_RECOGNITION message received');
      await startSpeechRecognition();
    } else if (type === 'STOP_SPEECH_RECOGNITION') {
      console.log('STOP_SPEECH_RECOGNITION message received');
      await stopSpeechRecognition();
    } else if (type === 'TOGGLE_SPEECH_RECOGNITION') {
      console.log('TOGGLE_SPEECH_RECOGNITION message received');
      await toggleSpeechRecognition();
    } else if (type === 'ENABLE_AVATAR_VIDEO') {
      console.log('ENABLE_AVATAR_VIDEO message received');
      updateApiState({ isVideoEnabled: true });
    } else if (type === 'DISABLE_AVATAR_VIDEO') {
      console.log('DISABLE_AVATAR_VIDEO message received');
      updateApiState({ isVideoEnabled: false });
    } else if (type === 'TOGGLE_AVATAR_VIDEO') {
      console.log('TOGGLE_AVATAR_VIDEO message received');
      updateApiState({ isVideoEnabled: !apiState?.isVideoEnabled });
    } else if (type === 'ENABLE_AVATAR_VOICE') {
      console.log('ENABLE_AVATAR_VOICE message received');
      updateApiState({ isAudioEnabled: true });
    } else if (type === 'DISABLE_AVATAR_VOICE') {
      console.log('DISABLE_AVATAR_VOICE message received');
      updateApiState({ isAudioEnabled: false });
    } else if (type === 'TOGGLE_AVATAR_VOICE') {
      console.log('TOGGLE_AVATAR_VOICE message received');
      updateApiState({ isAudioEnabled: !apiState?.isAudioEnabled });
    } else if (type === 'ENABLE_TEXT_BOX') {
      console.log('ENABLE_TEXT_BOX message received');
      updateApiState({ isTextEnabled: true });
    } else if (type === 'DISABLE_TEXT_BOX') {
      console.log('DISABLE_TEXT_BOX message received');
      updateApiState({ isTextEnabled: false });
    } else if (type === 'TOGGLE_TEXT_BOX') {
      console.log('TOGGLE_TEXT_BOX message received');
      updateApiState({ isTextEnabled: !apiState?.isTextEnabled });
    } else if (type === 'CHANGE_AVATAR') {
      console.log('CHANGE_AVATAR message received');
      await switchAvatar(avatarId);
    } else if (type === 'AVATAR_SPEAK_INTRO') {
      console.log('AVATAR_SPEAK_INTRO message received');
      processAvatarSpeakIntro(messageData.message);
    } else if (type === 'HANDLE_MESSAGE') {
      console.log('HANDLE_MESSAGE message received');
      if (avatar.isSpeaking) {
        avatar.stop();
      }
      handleUserInput(messageData.message);
    } else if (type === 'CHANGE_SPEAKING_RATE') {
      console.log('CHANGE_SPEAKING_RATE message received:', speakingRate);
      updateApiState({ speakingRate });
      speakingRateRef.current = speakingRate;
    } else if (type === 'STOP_SPEAKING') {
      console.log('STOP_SPEAKING message received');
      avatar.stop();
    } else if (type === 'LAYOUT_CHANGE') {
      console.log('LAYOUT_CHANGE message received');
      layoutRef.current = layout;
      updateApiState({ layout });
    } else if (type === 'COLOR_PROFILE_CHANGE') {
      const { colorProfile } = messageData;
      console.log('COLOR_PROFILE_CHANGE message received:', colorProfile);
      updateApiState({ colorProfile });
    } else if (type === 'RESUME_LAST_MESSAGE') {
      console.log('RESUME_LAST_MESSAGE message received');
      await resumeLastMessageFromLastSpokenSentence();
    } else if (type === 'SIZING_CHANGE') {
      console.log('SIZING_CHANGE message received');
      const sizes = messageData.sizes;
      if (sizes.customVideoWidth !== undefined) {
        customVideoWidthRef.current = sizes.customVideoWidth;
      }
      if (sizes.customVideoHeight !== undefined) {
        customVideoHeightRef.current = sizes.customVideoHeight;
      }
      if (sizes.customMessageHistoryWidth !== undefined) {
        customMessageHistoryWidthRef.current = sizes.customMessageHistoryWidth;
      }
      if (sizes.customMessageHistoryHeight !== undefined) {
        customMessageHistoryHeightRef.current = sizes.customMessageHistoryHeight;
      }
      if (sizes.customVideoObjectFit !== undefined) {
        customVideoObjectFitRef.current = sizes.customVideoObjectFit
      }
      forceUpdate({});
    } else if (type === 'STIMULUS_IMAGES') {
      console.log('STIMULUS_IMAGES message received');
      const stimulusImages = messageData.stimulusImages;
      setConversationHistory(prev => [
        ...prev,
        ...stimulusImages.map((image: string) => ({ 
          role: 'user' as const, 
          image,
          content: ''
        } as const))
      ]);
    }
  }, [avatar,
    sessionId,
    initSession,
    endSession,
    startSpeechRecognition,
    stopSpeechRecognition,
    toggleSpeechRecognition,
    switchAvatar,
    processAvatarSpeakIntro,
    handleUserInput,
    updateApiState,
    resumeLastMessageFromLastSpokenSentence,
    apiState
  ]);

  const [, forceUpdate] = useState({});

  useEffect(() => {
    handleMessageRef.current = handleMessage;
  }, [handleMessage]);

  useEffect(() => {
    if (apiState) {
      console.log('Updating avatarIdRef:', apiState.avatarId);
      avatarIdRef.current = apiState.avatarId;
      layoutRef.current = apiState.layout || 'vertical';
      forceUpdate({});
    }
  }, [apiState]);

  useEffect(() => {
    if (apiState?.speakingRate !== undefined) {
      speakingRateRef.current = apiState.speakingRate;
    }
  }, [apiState?.speakingRate]);

  useEffect(() => {
    if (apiState?.customVideoWidth !== undefined) {
      customVideoWidthRef.current = apiState.customVideoWidth;
    }
  }, [apiState?.customVideoWidth]);

  useEffect(() => {
    if (apiState?.customVideoHeight !== undefined) {
      customVideoHeightRef.current = apiState.customVideoHeight;
    }
  }, [apiState?.customVideoHeight]);

  useEffect(() => {
    if (apiState?.customMessageHistoryWidth !== undefined) {
      customMessageHistoryWidthRef.current = apiState.customMessageHistoryWidth;
    }
  }, [apiState?.customMessageHistoryWidth]);

  useEffect(() => {
    if (apiState?.customMessageHistoryHeight !== undefined) {
      customMessageHistoryHeightRef.current = apiState.customMessageHistoryHeight;
    }
  }, [apiState?.customMessageHistoryHeight]);

  useEffect(() => {
    if (apiState?.customVideoObjectFit !== undefined) {
      customVideoObjectFitRef.current = apiState.customVideoObjectFit;
    }
  }, [apiState?.customVideoObjectFit]);

  useEffect(() => {
    if (handleMessageRef.current) {
      window.addEventListener('message', handleMessageRef.current);
    }
    
    // Signal to the parent that the iframe is loaded
    window.parent.postMessage({ type: 'IFRAME_LOADED' }, '*');
    window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'IFRAME_LOADED' }))

    return () => {
      if (handleMessageRef.current) {
        window.removeEventListener('message', handleMessageRef.current);
      }
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [sessionId, handleMessageRef]);

  useEffect(() => {
    if (isReadyRef.current && apiState) {
      const initializeAndStartRecognition = async () => {
        await initializeAvatar();
        if (!apiState.microphoneMuted) {
          await startSpeechRecognition();
        } else {
          console.log('Microphone is muted, skipping speech recognition');
        }
      };
      if (!isAvatarInitializedRef.current && !speechRecognition.isRunning()) {
        console.log('Initializing and starting recognition');
        initializeAndStartRecognition();
      }
    } else {
      console.log('isReady or apiState is false, skipping initialization');
    }
  }, [apiState, speechRecognition, initializeAvatar, startSpeechRecognition]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get('sessionId');
    console.log('URL params:', urlParams.toString());
    console.log('Extracted sessionId:', id);
    if (id) {
      setSessionId(id);
      console.log('SessionId set:', id);
      fetchInitialState(id);
    } else {
      console.warn('No sessionId found in URL parameters');
    }
  }, [fetchInitialState]);

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.volume = apiState?.isAudioEnabled ? 1 : 0;
      audioRef.current.muted = !apiState?.isAudioEnabled;
    }
  }, [apiState?.isAudioEnabled]);

  useEffect(() => {
    if (apiState) {
      const colorProfile = apiState.colorProfile || 'default';
      document.body.style.backgroundColor = getColorProfile(colorProfile).backgroundColor;
      document.documentElement.style.backgroundColor = getColorProfile(colorProfile).backgroundColor;
    }
  }, [apiState]);

  useEffect(() => {
    if (apiState?.customVideoWidth || apiState?.customVideoHeight || apiState?.customMessageHistoryWidth || apiState?.customMessageHistoryHeight) {
      console.log('Custom size detected, forcing update');
      forceUpdate({});
    }
  }, [apiState?.customVideoWidth, apiState?.customVideoHeight, apiState?.customMessageHistoryWidth, apiState?.customMessageHistoryHeight]);


  const getColorProfileStyle = useCallback(() => {
      if (apiState?.colorProfile) {
        return {
          backgroundColor: getColorProfile(apiState?.colorProfile).backgroundColor,
          color: getColorProfile(apiState?.colorProfile).color,
        };
      }
      return {
        backgroundColor: getColorProfile('default').backgroundColor,
        color: getColorProfile('default').color,
      };
  }, [apiState?.colorProfile]);

  const getContainerStyle = useCallback(() => {
    return {
      display: 'flex',
      flexDirection: 'column' as const,
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      padding: '0px',
      boxSizing: 'border-box' as const,
      ...getColorProfileStyle(),
    };
  }, [getColorProfileStyle]);

  const getContentContainerStyle = useCallback(() => {
    return {
      width: '100%',
      height: '100%',
      display: 'flex',
      gap: layoutRef.current === 'horizontal' ? '1.5rem' : '0px',
      flexDirection: layoutRef.current === 'vertical' ? 'column' as const : 'row' as const,
      justifyContent: 'center',
      alignItems: 'center',
      ...getColorProfileStyle(),
    };
  }, [layoutRef, getColorProfileStyle]);

  const getVideoContainerStyle = useCallback(() => {
    if (!apiState?.isTextEnabled) {
      return {
        width: customVideoWidthRef.current || '100%',
        height: customVideoHeightRef.current || '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...getColorProfileStyle(),
      };
    }
    if (layoutRef.current === 'vertical') {
      return {
        width: customVideoWidthRef.current || '100%',
        height: customVideoHeightRef.current || '75%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...getColorProfileStyle(),
      };
    } else {
      return {
        width: customVideoWidthRef.current || '50%',
        height: customVideoHeightRef.current || '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: customVideoWidthRef.current ? 0 : 1,
        flexShrink: 0,
        flexBasis: 'auto',
        ...getColorProfileStyle(),
      };
    }
  }, [apiState?.isTextEnabled, layoutRef, getColorProfileStyle]);

  const getVideoStyle = useCallback(() => {
    return {
      width: layoutRef.current === 'vertical' ? '' : '100%',
      height: layoutRef.current === 'vertical' ? '100%' : '',
      maxWidth: customVideoWidthRef.current ? '100%' : layoutRef.current === 'vertical' ? '' : '560px',
      maxHeight: customVideoHeightRef.current ? '100%' : '',
      objectFit: customVideoObjectFitRef.current ? customVideoObjectFitRef.current : layoutRef.current === 'vertical' ? 'cover' as const : 'contain' as const,
      display: apiState?.isVideoEnabled ? 'block' : 'none',
      borderRadius: '10px',
      maskImage: 'linear-gradient(to right, transparent, black 3%, black 97%, transparent), linear-gradient(to bottom, transparent, black 3%, black 97%, transparent)',
      WebkitMaskImage: 'linear-gradient(to right, transparent, black 3%, black 97%, transparent), linear-gradient(to bottom, transparent, black 3%, black 97%, transparent)',
      maskComposite: 'intersect',
      WebkitMaskComposite: 'source-in',
      ...getColorProfileStyle(),
    };
  }, [apiState?.isVideoEnabled, layoutRef, getColorProfileStyle]);

  const getTextBoxStyle = useCallback(() => {
    if (!apiState?.isTextEnabled) {
      return { display: 'none' };
    }

    if (layoutRef.current === 'vertical') {
      return {
        width: customMessageHistoryWidthRef.current || '50%',
        height: customMessageHistoryHeightRef.current || '25%',
        marginTop: '0.5rem',
        ...getColorProfileStyle(),
      };
    } else {
      return {
        width: customMessageHistoryWidthRef.current || '45%',
        height: customMessageHistoryHeightRef.current || '100%',
        flexGrow: customMessageHistoryWidthRef.current ? 0 : 1,
        flexShrink: 0,
        flexBasis: 'auto',
        ...getColorProfileStyle(),
      };
    }
  }, [apiState?.isTextEnabled, layoutRef, getColorProfileStyle]);

  if (!apiState) {
    return <div>Loading...</div>;
  }

  return (
    <div style={getContainerStyle()}>
      <div style={getContentContainerStyle()}>
        <div style={getVideoContainerStyle()}>
          <video
            ref={videoRef}
            autoPlay
            playsInline
            muted
            style={getVideoStyle()}
          />
          <audio ref={audioRef} style={{ display: apiState?.isAudioEnabled ? 'block' : 'none' }} autoPlay />
        </div>
        <div style={getTextBoxStyle()}>
          <ChatUI
            messages={conversationHistory}
            accumulatedTranscript={accumulatedTranscript}
            isTranscriptFinal={isTranscriptFinal}
            colorProfile={apiState?.colorProfile || 'default'}
          />
        </div>
      </div>
    </div>
  );
}

export default App;